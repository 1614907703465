export interface photoCollectionsType {
    id: string;
    title: string;
    description: string;
    imgPath: string;
    imgName: string;
}

export interface contentPhotosType {
    id: number;
    name: string;
    path: string;
    directory: string;
    photos: photoCollectionsType[];
}

export const contentPhotos: contentPhotosType[] = [
    {
        id: 0,
        name: 'Ríos y Mares',
        path: '/sea',
        directory: 'photos-sea',
        photos: [
            {
                "id": "00000",
                "title": "Llegando a Bariloche",
                "description": "Este paisaje lo encontré llegando a San Carlos de Bariloche. Una ciudad fascinante. Detuve mi vehículo junto a una carretera, y me tomé unos minutos para apreciar este espléndido paisaje.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00000.JPEG"
            },
            {
                "id": "00001",
                "title": "La bici y el cielo",
                "description": "Dejemos la bici y disfrutemos del agua. Una instantánea de un momento de aquella tarde con un cielo cautivante. El tamaño de la bicicleta pone en evidencia lo enorme de ese esplendido cielo.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00001.JPG"
            },
            {
                "id": "00002",
                "title": "En la tarde",
                "description": "Una tarde de sol y fotos. ¡Qué momento tan lindo! Aquí aplique un efecto de barrido sobre el agua.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00002.JPG"
            },
            {
                "id": "00003",
                "title": "Lo último de la tarde",
                "description": "En los últimos minutos de la tarde logré algunos pocos reflejos. Era una tarde de principios de verano. Ya no había personas en ese lugar, solo quedaba yo con mi cámara. Ahí estaba esperando ese momento, hasta que llegó.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00003.JPG"
            },
            {
                "id": "00004",
                "title": "Agua sedosa",
                "description": "Un interesante efecto que se puede lograr con la ayuda de algún filtro ND. Ese muelle se ve potenciado ante este interesante efecto.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00004.JPEG"
            },
            {
                "id": "00005",
                "title": "Olas y rocas",
                "description": "Un mágico lugar en las Grutas de Punta del Este, Maldonado, Uruguay. Este sitio tiene unas intrincadas salidas al mar. Algo que pude reflejar en uno de los videos para Youtube.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00005.JPEG"
            },
            {
                "id": "00006",
                "title": "Un viejo protagonista",
                "description": "Aunque su tiempo se haya terminado, este viejo protagonista deja ver el paso del tiempo y lo árido del ambiente. Sus grietas en la madera, se asemejan a arrugas en la piel. Sin dudas que se trata de una instantánea cautivante que nos hace reflexionar.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00006.JPG"
            },
            {
                "id": "00007",
                "title": "Sol de la mañana",
                "description": "Esta foto fue tomada en San Martín de los Andes, en un lugar de camping al costado de un lago. Un lugar mágico, en el que disfrutar de una espléndida mañana. Pucará se llama el lugar.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00007.JPEG"
            },
            {
                "id": "00008",
                "title": "Un dia a término en Boca del Cufré",
                "description": "Esta imagen fue tomada en Boca del Cufré, en el departamento de San Jose, Uruguay. La misma fue testigo de una buena jornada de fotografías, Me acompañaba mi amigo y mascota Teo. En el agua le aplique un efecto de barrido que a mi juicio queda bastante interesante.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00008.JPEG"
            },
            {
                "id": "00009",
                "title": "Las playas de Arazati",
                "description": "El lugar en donde tomé esta foto fue Arazati, San Jose, Uruguay. En donde me encontré unos interesantes paisajes en sus bastas costas. Un lugar despoblado de personas, solo algunos pescadores también disfrutan de su paz. Alli pase muy buenos momentos, espero volver muy pronto.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00009.JPEG"
            },
            {
                "id": "00010",
                "title": "La Riviera",
                "description": "Esta foto fue tomada en La Riviera, Rocha, Uruguay. Un pueblito muy pequeño que convive en las costas de la Laguna de Rocha. Un sitio apasionante para los amantes de la paz y la tranquilidad. Allí se puede avistar una variada gama de aves, que hará la delicia de los fotógrafos.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00010.JPEG"
            },
            {
                "id": "00011",
                "title": "Arbolitos diferentes",
                "description": "Uno de los mejores sitios que me encontré en el año 2023. Queda en el departamento de Colonia, Uruguay. Si dudas que volveré a ese lugar, creo que hay mucho material para la fotografía de paisajes.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00011.JPEG"
            },
            {
                "id": "00012",
                "title": "Botes en el lago",
                "description": "Otra instantánea de Maldonado, Uruguay. Esta fue tomada en la Laguna Garzon. Allí estuve de visita con mi amigo Teo, mi fiel perro que siempre me acompaña. Y en cierto punto, creo que él ha aprendido a tener paciencia, mientras me tomo mi tiempo para lograr fotos como esta.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00012.JPEG"
            },
            {
                "id": "00013",
                "title": "Un viejo Puente Ferroviario",
                "description": "Lo que para algunos es restos del pasado, quizás un pasado más próspero, para otros es la cuna de la fotografía. En un lugar de Rosario, Departamento de Colonia es que logré esta imagen.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00013.jpg"
            },
            {
                "id": "00014",
                "title": "Disfrutando con amigos",
                "description": "Un día, una aventura, una buena jornada. Quizás era un dia ventoso, pero ellos parece que no tenían problemas en disfrutar de aquel muelle. Una foto tomada en la Rambla de Montevideo.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00014.jpeg"
            },
            {
                "id": "00015",
                "title": "La calma",
                "description": "Estos juncos en un arroyo de Maldonado, Uruguay, no hacen más que transmitirnos calma. Los reflejos ayudan a componer esta imagen.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00015.JPEG"
            },
            {
                "id": "00016",
                "title": "En el olvido",
                "description": "Así está este barco en las costas de San José, Uruguay. Allí espera que el paso del tiempo haga su proceso. El día que visité ese lugar, pude lograr unas interesantes imágenes, hubo varios encuadres interesantes. Algo que puede documentar en un video para Youtube.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00016.jpeg"
            },
            {
                "id": "00017",
                "title": "En lo alto del cerro",
                "description": "Esta fabulosa vista se puede apreciar en un mirador de San Martín de los Andes. Un lugar al que espero volver pronto.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00017.JPG"
            },
            {
                "id": "00018",
                "title": "En el Valle del Lunarejo",
                "description": "Si no me equivoco, esta es la cascada del Indio en el Valle del Lunarejo, Rivera, Uruguay. Era un día de mucho calor, en el que aproveche a darme unos buenos baños. Una visita que sirvió para lograr unas buenas imágenes.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00018.jpeg"
            },
            {
                "id": "00019",
                "title": "Explorando campo adentro",
                "description": "Una de las cosas que más me gusta es encontrarme con sitios como estos. Se trata de una laguna en un paraje perdido, allí tuve un interesante encuentro con un ave de la especie: Martin pescador. Esta foto fue tomada en Riachuelo, Colonia, Uruguay.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00019.jpg"
            },
            {
                "id": "00020",
                "title": "Las playas de San Pedro",
                "description": "Esta foto fue tomada en las Barrancas de San Pedro, en el departamento de Colonia, Uruguay. Se trató de otro día de fotografía en el que firmé otro video para el canal de Youtube. En la oportunidad, cayó la tarde y el sol ya en el horizonte me permitió iluminar la escena con gran detalle. El resultado, compuesto por una variedad de formas, tonalidades y colores creo que conformo una escena muy interesante.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00020.JPG"
            },
            {
                "id": "00021",
                "title": "Recorrida en El Chaltén",
                "description": "Esta foto fue tomada en el Lago del Desierto, El Chaltén, Santa Cruz, Argentina. En la ocasión estuve recorriendo este interesante sitio, en un día gris y muy frío. La topología del lugar, demuestra que sus costas son bastante áridas y desoladas. No obstante, es un sitio lleno de vegetación y fauna.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00021.JPEG"
            },
            {
                "id": "00022",
                "title": "Un Regalo del Cielo",
                "description": "Esta foto fue tomada en el predio que ronda El Tren del Fin del Mundo, Ushuaia, Argentina. Mientras la gran cantidad de turistas optaba por aprestarse a recorrer el lugar a bordo de este tren, yo opté por apartarme y encontrar este sitio. Debajo tenía un arroyo y encima un imponente cerro. En el algún momento por unos escasos minutos salió el sol y me brindó un espectáculo para mis ojos y el lente de mi cámara. Increíblemente los rayos del sol dieron detrás del árbol, sobre el cerro, y esto me permitió lograr un impresionante contraste. ¡Qué bonito lugar!",
                "imgPath": "photos-sea/",
                "imgName": "photo-00022.JPEG"
            },
            {
                "id": "00023",
                "title": "Montevideo y su rambla",
                "description": "Este sitio ha sido la cuna para mi desarrollo como fotógrafo, sus calles, parques y su costas, fueron los escenarios de eternas prácticas y enfoques. En la prueba y error se encuentra la perfección. Si bien es inalcanzable, cada nueva composición nos acerca un poco más con ese ideal que queremos alcanzar. Esta foto fue tomada en el Barrio de Parque Rodo, Montevideo, Uruguay.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00023.JPG"
            },
            {
                "id": "00024",
                "title": "Los tres hermanos",
                "description": "Estos tres arbolitos fueron mi centro de atención en aquel día de fotografía. Probé varios enfoques y resultaron algunas fotos interesantes. El contraste del cielo con sus delgadas ramas, hacen las delicias de mis ojos. Esta foto fue tomada en una playa perdida de Colonia, Uruguay.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00024.JPEG"
            },
            {
                "id": "00025",
                "title": "Las gaviotas",
                "description": "Esta composición es muy singular. Tuve que realizar varias fotos para conformarla, se trata de un Focus Stacking o Apilamiento de imágenes. A mi juicio, el resultado final es muy cautivante. La distancia entre estas aves hacía imposible el captarlas todas juntas. Esta foto fue tomada en la Laguna Garzon, Maldonado, Uruguay.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00025.JPG"
            },
            {
                "id": "00026",
                "title": "Mirando de reojo",
                "description": "Estuve un rato largo enfocadolo. Sabía que tarde o temprano me regalaría una posición perfecta para conformar mi foto. Estaba lejos, tuve que usar un lente de 500mm. Pero la paciencia al final tuvo su resultado. Creo que la gama de colores y tonalidades, logra una de las más bonitas fotos que he alcanzado.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00026.jpeg"
            },
            {
                "id": "00027",
                "title": "La mar de la tranquilidad",
                "description": "Esas rocas atestiguan el paso del tiempo, y la calma del momento. A lo lejos se puede ver un barco, que conforma la imagen. El horizonte tiene una magia muy importante en las fotografía, esa dualidad del cielo y el mar, cautiva la mirada del espectador. Esta foto fue tomada en la rambla de Punta Carretas, Montevideo, Uruguay.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00027.JPG"
            },
            {
                "id": "00028",
                "title": "Elegante ante todo",
                "description": "La elegancia enriquece la postura de esta ave, la gama de tonalidades del agua y su entorno conforman una espectacular visión de lo que ocurría en ese lugar. Tuve la suerte de estar allí presente para admirarla. A veces uno se queda atónito observando lo que pasa en el lugar y se olvida de tomar la foto. Pues este no fue el caso. Esta imagen fue lograda en la salida al rio del arroyo Pando, Canelones, Uruguay",
                "imgPath": "photos-sea/",
                "imgName": "photo-00028.JPG"
            },
            {
                "id": "00029",
                "title": "Un hombre y su amigo",
                "description": "Una imagen que simboliza la unión, la amistad, la paciencia y la plenitud del momento. Al fondo un velero, completa la imagen, transmitiendo un ambiente muy interesante. Esta foto fue tomada en el Faro de Punta Carretas, Montevideo, Uruguay.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00029.JPG"
            },
            {
                "id": "00030",
                "title": "El cielo y la ciudad",
                "description": "Una panorama que mezcla la grandeza del cielo, con la urbanización debajo. Para conformar esta imagen se utilizaron varias fotos en formato vertical, unidas en un panorama horizontal. La imagen fue lograda en las Canteras del Parque Rodó, Montevideo, Uruguay.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00030.JPG"
            },
            {
                "id": "00031",
                "title": "Las playa y sus ocupantes",
                "description": "Esta foto fue conformada un día que hubo creciente. Entonces la casilla de guardavidas, al parecer quedó en contacto con el agua. Situación que no detuvo a algunos bañistas para continuar con sus actividades. Esta composición fue lograda en Atlántida, Canelones, Uruguay.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00031.jpeg"
            },
            {
                "id": "00032",
                "title": "Llegando tarde..",
                "description": "Su fiel amigo llega para acompañarlo en otro día de pesca. No hay tiempo que perder, ese lugar es para él. Foto tomada en el Faro de Punta Carretas, Montevideo, Uruguay.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00032.jpeg"
            },
            {
                "id": "00033",
                "title": "Soñado..",
                "description": "Un atardecer, unas rocas, el sol, otro fantástico día que se termina, Suerte que estaba allí para atestiguar ese espléndido atardecer. Foto tomada en la rambla de Punta Carretas, Montevideo, Uruguay.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00033.JPG"
            },
            {
                "id": "00034",
                "title": "Lindo para una siesta",
                "description": "Mientras algunos disfrutan de sus pasiones, como la pesca, otros aprovechan para tomar una siesta. Quizás este no sea el lugar más cómodo del mundo, no obstante, lo importante es la compañía. Ellos saben que el otro está ahí. Foto tomada en el Faro de Punta Carretas, Montevideo, Uruguay.",
                "imgPath": "photos-sea/",
                "imgName": "photo-00034.jpeg"
            }
        ],
    },
    {
        id: 1,
        name: 'Mi tierra, Uruguay',
        path: '/uruguay',
        directory: 'photos-uruguay',
        photos: [
            {
                "id": "00000",
                "title": "Parque Hotel, O la sede Mercosur ",
                "description": "En mi opinión, uno de los edificios más destacados de mi barrio es el emblemático edificio del Parque Rodó, en Montevideo. Hoy en día, es la sede del Mercosur, pero en sus inicios fue un reconocido hotel que recibió a muchos famosos. En aquella época, esta zona de Montevideo no era más que un balneario o una zona costera mucho menos poblada de lo que es hoy. Como yo lo llamo: El Rey del Parque Rodó, este majestuoso edificio atestigua el paso del tiempo y los cambios en nuestra sociedad. Verlo de pie, imponente y lleno de historia, me llena de orgullo y nostalgia. ¡Por muchos años más de historias y recuerdos compartidos bajo su sombra protectora!",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00000.jpeg"
            },
            {
                "id": "00001",
                "title": "Las Sierras de Carapé, Maldonado",
                "description": "Una imponente formación de valles se destaca en Maldonado, Uruguay. Se trata de las Sierras de Carapé, que representan lo más alto del país. Sus continuos cerros son testigos de la belleza sin igual de esta zona rural. A pesar de ser un área privada, es posible disfrutar de su esplendor desde sus caminos, o solicitando permiso a algún productor rural para pasear por su inmensidad. Cada colina, cada valle, parece susurrar historias antiguas de la tierra. Este lugar maravilloso es un tesoro escondido que nos invita a conectar con la naturaleza y a maravillarnos con la serenidad que solo estos paisajes pueden ofrecer.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00001.JPG"
            },
            {
                "id": "00002",
                "title": "El embalse de una represa",
                "description": "Uno de los atractivos más notables de Canelones, Uruguay, es una serie de represas que sirven para diversos propósitos. Esta foto fue tomada en las inmediaciones de una de ellas, y se puede apreciar cómo se van creando una suerte de pequeñas islas con exuberante vegetación. Este lugar es ideal para el descanso, el relax y la contemplación de aves. Ese maravilloso verde forraje no hace más que embellecer el paisaje, creando un ambiente sereno y rejuvenecedor. Cada rincón invita a perderse en la tranquilidad y a reconectar con la naturaleza.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00002.JPG"
            },
            {
                "id": "00003",
                "title": "La rambla de Montevideo y sus vistas",
                "description": "Recorrer la rambla de Montevideo es una forma de recargar energías, llenarnos de voluntad y propósito para llevar a cabo nuestros objetivos. Caminar, pensar y respirar son partes fundamentales de nuestros ciclos internos. Si además, podemos ver con nuestros propios ojos paisajes tan hermosos como estos, la experiencia se convierte en un regalo para el alma. La brisa del mar, el sonido de las olas y la vista del horizonte nos invitan a reflexionar y encontrar la paz interior. La rambla nos ofrece no solo un espacio físico para caminar, sino también un refugio emocional donde podemos renovar nuestras fuerzas y enfrentar el día a día con una nueva perspectiva.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00003.JPG"
            },
            {
                "id": "00004",
                "title": "Las costas de San Jose, Uruguay",
                "description": "Si viajamos desde Montevideo hacia Colonia, en el camino pasamos por el departamento de San José, específicamente por sus encantadoras costas. Esta foto fue tomada en Kiyú, un balneario vacacional que tiene un encantador aire vintage o retro que tanto me gusta. En esta oportunidad, la desembocadura de un arroyo en el Río de la Plata formaba un interesante salto de agua, adornado por espléndidas dunas de arena. Este paisaje no solo es visualmente impactante, sino que también evoca una sensación de tranquilidad y nostalgia. Kiyú, con su belleza natural y su atmósfera única, nos invita a detenernos un momento, a disfrutar del presente y a dejarnos llevar por la magia del entorno.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00004.JPEG"
            },
            {
                "id": "00005",
                "title": "Dando los primeros pasos",
                "description": "Una madre y su pequeña hija son las protagonistas de esta instantánea, creando un interesante contraste con el fondo donde se ve un barco carguero llegando al puerto de Montevideo. Esta foto fue difícil de lograr debido a la técnica de focus stacking o apilación de imágenes utilizada. Sin embargo, creo que el resultado final habla por sí mismo. Esta imagen me transmite muchas cosas: la calidez del vínculo entre madre e hija, la inmensidad y el movimiento del puerto, y la fusión de lo humano con lo industrial en un solo cuadro. Es una escena que captura la ternura de un momento íntimo en medio de la realidad del movimiento comercial detrás, en las cercanías del puerto, recordándonos la belleza de los pequeños instantes en nuestras vidas.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00005.JPEG"
            },
            {
                "id": "00006",
                "title": "En una cálida tarde",
                "description": "En una cálida tarde de verano, un grupo de jóvenes disfruta de la costa, el muelle y el sol, sin importarles mucho la mirada de un adulto en las cercanías. Esos momentos son memorables, son los que recordaremos más adelante como protagonistas de viejas etapas de nuestra vida. Ojalá podamos volver a perdernos en la vorágine de la emoción, el calor y la fraternidad. Es en esos instantes de despreocupada alegría donde encontramos la verdadera esencia de la juventud, y es a través de estos recuerdos que mantenemos viva la chispa de esos días dorados.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00006.JPEG"
            },
            {
                "id": "00007",
                "title": "Perdido en el horizonte",
                "description": "¿Qué puedo decir de la maravillosa vista que nos ofrece nuestro astro rey, especialmente cuando se mezcla con las nubes y el mar? En esta ocasión, el lente gran angular juega a nuestro favor, creando unas imponentes formas y líneas que nos dirigen la vista hacia el centro de esta postal. La combinación del sol, las nubes y el mar crea un espectáculo natural incomparable, lleno de belleza y serenidad. Es como si la naturaleza misma nos guiara hacia un punto de enfoque, invitándonos a contemplar la grandeza del universo y a apreciar la majestuosidad de nuestro entorno. Cada elemento en esta escena parece trabajar en armonía para cautivarnos y darnos un momento de asombro y reflexión. Es en momentos como estos donde nos sentimos verdaderamente conectados con el mundo que nos rodea y recordamos la importancia de detenernos y apreciar la belleza que nos rodea.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00007.jpeg"
            },
            {
                "id": "00008",
                "title": "A lo lejos algo maravilloso",
                "description": "Una postal en vertical que nos muestra lo maravilloso que es nuestro mundo, como cada día, en sus atardeceres nos recuerda lo vivos que estamos. No puedo más que agradecer por ser parte de lo que nos rodea, y pensar en la suerte que tendré de volverlo a ver, el día próximo. Mirar hacia el horizonte iluminado por los últimos rayos del sol nos llena de esperanza y gratitud, nos hace reflexionar sobre el valor de cada día y la importancia de vivir plenamente. Así que, sí, cada nuevo día es una bendición que esperamos con ansias, sabiendo que estará lleno de nuevas oportunidades y experiencias maravillosas.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00008.JPG"
            },
            {
                "id": "00009",
                "title": "Esa grandeza que me nutre",
                "description": "El haber pasado una mañana recorriendo intrincados rincones con una cerrada vegetación para luego descubrir esto, no hace más que embellecerme de tanta grandeza. Esto es parte de un paisaje que encontré en las Sierras de Carapé, Maldonado, Uruguay. Se trata de la naciente del arroyo José Ignacio. Este paisaje es más que una simple vista; es una experiencia que permanecerá grabada en mi memoria como un recordatorio de la grandeza y la belleza que nos rodea, esperando ser descubierta y apreciada.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00009.JPG"
            },
            {
                "id": "00010",
                "title": "Las Cárcavas de Rocha",
                "description": "Uno de los paseos más frecuentes si estamos cerca de La Pedrera, Rocha, son las milenarias Cárcavas. Originalmente creadas bajo el mar hace muchísimos años, hoy nos recuerdan cómo la topología y el paso del tiempo han cambiado. Sin dudas, se trata de un lugar fascinante para visitar. Cada surco y cada formación rocosa nos cuentan una historia, nos invitan a reflexionar sobre la fragilidad y la belleza de nuestro planeta. Es un recordatorio de que, aunque el tiempo y los elementos puedan transformar el paisaje, la naturaleza siempre encuentra formas de reinventarse y sorprendernos con su magnificencia.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00010.JPEG"
            },
            {
                "id": "00011",
                "title": "Sobre el lecho del río",
                "description": "Esos árboles, inclinados sobre un lado, pareciendo acercarse al lecho del río, me recuerdan que estos son seres vivos. Y tal como sucede con otras especies, el agua tiene un atractivo irresistible para ellos. Más allá de que se trate de su sustento, ellos nos quieren decir algo. Solo tenemos que saber escuchar. Cada río lleva consigo una historia, un flujo constante de energía y renovación. Y al observar estos árboles, nos damos cuenta de que también nosotros somos parte de esta historia, parte de esta danza eterna entre la tierra y el agua. Solo necesitamos abrir nuestros corazones y dejar que la naturaleza nos hable, enseñándonos sus lecciones más profundas.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00011.JPEG"
            },
            {
                "id": "00012",
                "title": "Observando la cantera",
                "description": "Existen muchos sitios como este en la zona costera, hacia el lado de Colonia, Uruguay. Se trata de viejos espacios en desuso que en algún momento sirvieron para la extracción de minerales como el cuarzo, mármol y granito, entre otros. Hoy, mucho tiempo después, la naturaleza ha recobrado a su manera estos espacios. Caminar por estos lugares es como adentrarse en un mundo olvidado pero lleno de vida, donde cada rincón cuenta una historia y cada piedra guarda secretos. Es una oportunidad para reconectar con la tierra y con nosotros mismos, para reflexionar sobre el impacto que tenemos en el medio ambiente y la importancia de preservar y respetar la naturaleza en todas sus formas.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00012.JPG"
            },
            {
                "id": "00013",
                "title": "Vida entre nosotros",
                "description": "Esta imagen me encanta, me transmite espacio, orientación y ubicación en este mundo pasajero. Los árboles, con sus raíces arraigadas en el paraje junto a la costa, son testigos de tiempos pasados y presentes, esperando tranquilos como siempre. Es lamentable cómo el ser humano ha perdido el respeto por los árboles. Demasiado a menudo, la solución a cualquier inconveniente es simplemente talarlos, sin considerar el papel vital que desempeñan. Una verdadera lástima. Los árboles son mucho más que meros elementos del paisaje; son guardianes de la naturaleza, hogar de innumerables formas de vida y compañeros silenciosos en nuestro viaje por la vida. Su presencia nos conecta con la tierra y nos recuerda nuestra responsabilidad de proteger y preservar el mundo natural que nos rodea.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00013.JPEG"
            },
            {
                "id": "00014",
                "title": "Al olvido y a la deriva",
                "description": "Perdió su amarre y quedó a la deriva. Esta importante boya juega un papel fundamental en nuestros ríos y mares, guiando a esos pequeños barcos que carecen de un instrumental muy avanzado. Quién sabe cuál será su historia y cómo llegó allí. Sin dudas, impacta encontrarla volcada sobre su lado. Otra joya que encontré en las playas de Kiyú, San José, Uruguay.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00014.JPG"
            },
            {
                "id": "00015",
                "title": "Perdidos en el horizonte",
                "description": "Allí, a lo lejos, se los puede ver. Ellos son testigos inmutables del paraje. La estepa predomina en estas zonas de Maldonado, Uruguay, una región rural ubicada en las alturas. Los propietarios de estos campos han encontrado una forma rentable de lucrar con la venta de electricidad. Por un lado, lo positivo es que esto pone en segundo plano la ganadería, una actividad que ha devastado nuestros campos. Sin embargo, por otro lado, algo negativo es que las aves parecen no adaptarse y son víctimas casuales de su presencia. En fin, qué difícil es alcanzar un equilibrio entre rentabilidad y medio ambiente. Estos molinos de viento representan una encrucijada entre el progreso económico y la preservación de la biodiversidad. En última instancia, debemos encontrar soluciones que respeten y protejan nuestro entorno natural mientras impulsamos el desarrollo sostenible.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00015.JPEG"
            },
            {
                "id": "00016",
                "title": "Rinconcitos escondidos",
                "description": "Me encanta encontrarme con estos espacios abiertos y hermosos, donde la naturaleza me abruma. Es lo que busco en cada fotografía: ver y sentir el aire de ese lugar en nuestro rostro. Este imponente paraje lo encontré en el Cerro Catedral, Maldonado, Uruguay, el punto más alto del país con unos 500 metros de altura sobre el nivel del mar. Cada visita a lugares como este es una oportunidad para renovar nuestro espíritu y recordar la importancia de proteger y preservar la naturaleza. Es un recordatorio de que, aunque seamos pequeños en comparación con la grandeza de la tierra, cada uno de nosotros tiene el poder de marcar una diferencia y contribuir a un mundo más sostenible y equilibrado",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00016.JPEG"
            },
            {
                "id": "00017",
                "title": "Con la cámara en la mano",
                "description": "Otra impresionante instantánea del Cerro Catedral, Uruguay. Es un lugar mágico para recorrer y descubrir. Aunque el predio sea privado, se puede visitar y no queda muy distante del camino vecinal que conduce a él. Pasé un día muy entretenido buscando los encuadres más apasionantes. Es una experiencia que se disfruta mucho más con una cámara en la mano.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00017.JPG"
            },
            {
                "id": "00018",
                "title": "La majestuosidad de Rosario Colonia",
                "description": "Este es uno de esos lugares que encontré casi por casualidad. Se trata de un paraje ubicado en la ciudad de Rosario, Colonia, muy cerca de Montevideo. Al llegar al lugar, estaba nublado, pero después de un rato, el sol hizo su aparición, dándome la oportunidad de retratar la belleza de este sitio. Ese antiguo puente en el fondo y el contraste del verde del pasto crean una instantánea espectacular a mis ojos.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00018.JPEG"
            },
            {
                "id": "00019",
                "title": "Allí, de paso!",
                "description": "Allí estaba él, de paso por aquel lugar. Fue una oportunidad que encontré muy interesante para sacar mi cámara. Me apresuré, cambié el objetivo al de largo alcance, ajusté la configuración y en poco tiempo todo quedó listo. El resto es historia. El lugar fue Rosario, Colonia, Uruguay.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00019.JPG"
            },
            {
                "id": "00020",
                "title": "Viejo puente de un ferrocarril",
                "description": "Este viejo puente hoy atestigua el paso del tiempo. Originalmente construido para soportar el paso de un ferrocarril cargado de materia prima lista para ser elaborada, hoy es parte del recuerdo. Nos muestra cómo en algún momento el progreso estaba en auge y la industrialización era un importante sustento de esta localidad. Ahora, este puente olvidado en Rosario, Colonia, no hace más que embellecer un espectacular paisaje.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00020.JPEG"
            },
            {
                "id": "00021",
                "title": "Espacio para recargar las energias",
                "description": "No muy lejos de Montevideo se encuentra este lugar. Aún cerca de las ciudades, existen sitios como este que nos proveen un escape de nuestras rutinas. Es un espacio para pensar y recargar las pilas para continuar con nuestras vidas, algo muy necesario hoy en día.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00021.JPEG"
            },
            {
                "id": "00022",
                "title": "Cantera Carrara",
                "description": "Este es el resultado de la erosión del suelo durante muchos años. Se trata de una imponente cantera de minerales, en la cual una de sus zonas ya no puede seguir siendo excavada porque no queda más nada que remover. Hoy en día, sirve como un paisaje fascinante para los más curiosos. Un lugar espectacular para una escapada. Se encuentra en Maldonado, Uruguay.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00022.JPEG"
            },
            {
                "id": "00023",
                "title": "Afrontando lo que venga!",
                "description": "Se trata de una de mis fotos preferidas, una instantánea que transmite firmeza y fortaleza ante las inclemencias del tiempo. Ahí está ese árbol, sin hojas, casi seco, soportando el clima y la desolación de su entorno. La cámara captó perfectamente esta escena. Está ubicado en El Pinar, Canelones, Uruguay.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00023.JPG"
            },
            {
                "id": "00024",
                "title": "Historias que contar",
                "description": "Este árbol tendrá mil historias que contar. En algún momento, estuvo cubierto de verde vegetación. Hoy yace seco, en un ambiente árido, donde es difícil conseguir lo que tanto necesita: el agua. No obstante, nos recuerda la fortaleza que hay que tener en momentos difíciles y cómo enfrentarlos con determinación. No importa su desgastado aspecto, él está allí para que aprendamos de su resistencia.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00024.JPEG"
            },
            {
                "id": "00025",
                "title": "Carrara and company",
                "description": "Otra instantánea de la Cantera Carrara, en Maldonado, Uruguay, muy cerca de Pan de Azúcar. Es un lugar con un bonito mirador, perfecto para pasar un rato y tomar unos mates. Su imponente belleza nos apabulla y nos recuerda cómo una zona que alguna vez fue concebida para ser socavada, hoy es el hábitat de aves y otras especies. Una maravilla sin igual.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00025.jpeg"
            },
            {
                "id": "00026",
                "title": "Grandeza",
                "description": "En algún momento nos diste sombra, en algún momento tus hojas eran verdes. Hoy, el paso del tiempo y las inclemencias te han vencido. No obstante, tu fuerte tronco te mantiene presente, recordándonos la fortaleza que encontramos en tu naturaleza. Gracias por transmitirnos eso que tanto necesitamos.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00026.JPG"
            },
            {
                "id": "00027",
                "title": "Apacible",
                "description": "La definición de \"apacible\" es de buen temple, tranquilo, agradable. Pues eso es lo que encuentro en esta instantánea, algo que me transmite una suma de cosas. En el fondo, me recuerda lo maravilloso de la naturaleza: su capacidad para ofrecernos momentos de paz y serenidad, incluso en medio del bullicio y el ajetreo diario. Es un recordatorio de la importancia de detenernos y apreciar la belleza que nos rodea, y de encontrar calma y consuelo en los paisajes que nos regala el mundo natural.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00027.JPG"
            },
            {
                "id": "00028",
                "title": "La bici y el cielo",
                "description": "¡Qué afortunado fui de estar allí en ese preciso momento! La vista golpeaba sobre mi derecha, una conjunción del cielo, el mar y la bicicleta sobre la arena componían una postal mágica. ¡Qué suerte la mía de estar allí, qué suerte tener mi cámara! Cada clic capturaba la esencia de ese instante irrepetible, un tesoro que guardaré para siempre en mis recuerdos. La naturaleza, una vez más, me regalaba una obra de arte efímera, pero eternamente grabada en mi corazón.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00028.JPG"
            },
            {
                "id": "00029",
                "title": "Terminando la tarde",
                "description": "Fui testigo de ese momento cuando la tarde llegaba a su fin, disfrutando de su paz y tranquilidad mientras esperaba la caída de la noche. Otro día de pesca y exploración queda atrás. Ahora, la suave luz vespertina crea una danza que invita al descanso. ¡Mañana será otro día lleno de nuevas oportunidades!",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00029.JPG"
            },
            {
                "id": "00030",
                "title": "El Plumacho ",
                "description": "El Plumacho, como se le llama en el Río de la Plata, es una planta que se encuentra por todas partes. Tiene una gracia muy particular, muy diferente a otros tipos de plantas verdes, lo que añade un toque distintivo al paisaje. Sin embargo, fotografiarlo de manera que obtengamos una composición adecuada puede resultar desafiante. Otro día de fotografías, otra experiencia aprendida.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00030.JPG"
            },
            {
                "id": "00031",
                "title": "En tu momento de paz",
                "description": "Te pude atrapar en tu momento de paz interna, disfrutando de tu pasatiempo, la pesca. Gracias por permitirme ser parte de ese momento. No sé si te diste cuenta de que estaba allí detrás con mi cámara, esperando para lograr una composición espectacular. Creo que el resultado habla por sí mismo. Esta fotografía la tomé en Punta del Este, Maldonado, Uruguay.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00031.jpeg"
            },
            {
                "id": "00032",
                "title": "Jugando con mi amiga",
                "description": "No puedo decir con exactitud dónde queda el sitio en el que encontré este paisaje. Sé que fue difícil llegar a él; fue el resultado de un día de exploración fotográfica. Otro día en el que aprendí cosas maravillosas con mi amiga, la cámara. Ella habla un lenguaje que hay que saber interpretar; me pide que haga ciertos ajustes, algo que con el tiempo hemos aprendido a entender y a jugar armoniosamente con sus capacidades.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00032.JPEG"
            },
            {
                "id": "00033",
                "title": "Sutilezas del ambiente",
                "description": "Ese día había muchas cosas que llamaban mi atención. No solo el personaje en el fondo con su caña de pescar, sino también el imponente paisaje que encontré a mi alrededor. Experimenté con varias composiciones y perspectivas, y en definitiva, fue otro día apasionante. Esta foto fue tomada en Punta del Este, Uruguay.",
                "imgPath": "photos-uruguay/",
                "imgName": "photo-00033.jpeg"
            }
        ]
    },
    {
        id: 2,
        name: 'Paisajes que Inspiran',
        path: '/paisajes',
        directory: 'photos-paisajes',
        photos: [
            {
                "id": "00034",
                "title": "Árido pero bonito",
                "description": "Un paisaje, un árbol, una playa desierta: estos son los elementos de esta espectacular vista. El rol del fotógrafo de paisajes es comprender el entorno natural, tejer entre todas sus aristas para componer una imagen final que refleje la grandeza de nuestro mundo. No importa si el entorno es desafiante o aparentemente falto de contenido; en la cámara y en la habilidad del fotógrafo reside la capacidad para capturar esa grandeza.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00034.JPG"
            },
            {
                "id": "00035",
                "title": "En la cima de un cerro",
                "description": "Esta foto la logré en la cima del cerro del Parque Municipal Grutas De Salamanca, un espectacular lugar en Maldonado, cerca de Aigua. Ese árbol me llamaba, me pedía una composición que reflejara su crecimiento, su fortaleza y su resistencia al ambiente. Es un árbol que hoy es pequeño, pero sé que mañana será enorme, un testimonio viviente del poder de la naturaleza para crecer y perseverar ante cualquier adversidad.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00035.JPG"
            },
            {
                "id": "00036",
                "title": "Un suelo árido, que florece.",
                "description": "De este duro suelo emerge este árbol. En un ambiente rocoso, tosco y complicado, donde normalmente el agua habría inundado todo, en esta ocasión se permitía ver la composición del suelo. Sin importar lo árido que pueda ser, la vida vegetal encuentra su camino. Esta foto fue tomada en las cercanías de Colonia, Uruguay.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00036.JPG"
            },
            {
                "id": "00037",
                "title": "El Esplendor del Lago al Atardecer en Punta Carretas",
                "description": "En la zona de Punta Carretas, más precisamente cerca del barrio Parque Rodó, se encuentra este lago, un paisaje muy atractivo que la Intendencia de Montevideo se encarga de mantener. Durante el atardecer, la luz del sol resalta su esplendor, mostrando toda su belleza. Fue en ese preciso momento cuando tuve la oportunidad de capturar esta fotografía.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00037.JPG"
            },
            {
                "id": "00038",
                "title": "La magia del cerro en Grutas de Salamanca",
                "description": "Sobre este cerro, ubicado en las Grutas de Salamanca, en Aiguá, Maldonado, Uruguay, logré capturar esta fabulosa composición. El cielo, el color del pasto y las rocas que enmarcan el paisaje, creo que fueron los ingredientes perfectos para una espectacular composición.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00038.JPG"
            },
            {
                "id": "00039",
                "title": "Un camino que nos enseña",
                "description": "Seguir caminos en la fotografía es una manera de narrar una historia visual. Estos senderos tienen un inicio y un final, y a lo largo de su recorrido revelan el entorno que los rodea. Es crucial aprovechar estas características, ya que contribuyen a la composición de la imagen. Esta fotografía fue tomada en Aiguá, Maldonado, Uruguay.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00039.JPEG"
            },
            {
                "id": "00040",
                "title": "Amando lo que hacemos",
                "description": "Un ave majestuosa, un paisaje impresionante, un momento de pura magia que afortunadamente pude capturar con mi cámara. Estos son los instantes en la fotografía que cobran un valor incalculable: el esfuerzo por llegar al lugar adecuado, el sacrificio, la dedicación de horas, todo ello para presenciar y luego admirar el resultado final. Es un proceso apasionante, especialmente para aquellos que amamos la fotografía.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00040.JPEG"
            },
            {
                "id": "00041",
                "title": "Sensaciones a través de las imágenes",
                "description": "Soy un apasionado impulsor y creador de este tipo de imágenes que transmiten paz y tranquilidad. Forman parte de mi objetivo: capturar una imagen simple que nos invite a bajar el ritmo frenético de la vida diaria, calmar las prisas y los nervios. Busco transmitir esa sensación que tanto aprecio, que nos conecta con lo esencial y nos llena de vida.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00041.JPEG"
            },
            {
                "id": "00042",
                "title": "imperturbable",
                "description": "Solitario y sereno, este árbol se erige en su propio rincón del mundo, resguardado en un espacio protegido de los daños humanos. Tuvo la fortuna de nacer en el jardín de un museo, un refugio seguro en medio de la vorágine urbana. Allí comparte sus días con sus semejantes, siendo testigo silente de los cambios en su entorno. Mientras estos árboles no representen una amenaza para el hombre, él seguirá creciendo y floreciendo, imperturbable.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00042.JPG"
            },
            {
                "id": "00043",
                "title": "Cerro Arequita",
                "description": "El Cerro Arequita en Minas, Uruguay, es un paisaje imprescindible para quienes transitan por sus cercanías. Considerado uno de los lugares más visitados del país, su imponente presencia domina el horizonte. En esta composición, el cerro se destaca majestuoso contra el cielo gris, revelando algunos espacios de azul entre las nubes. Su gran tamaño se erige imponente sobre la tierra, mientras que en su base, el follaje verde añade un contraste natural y exuberante a la escena.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00043.JPEG"
            },
            {
                "id": "00044",
                "title": "Una pequeña represa",
                "description": "Esta represa, ingeniosamente diseñada, aprovecha el poder del agua para generar electricidad mediante grandes dinamos, que convierten la energía hidráulica en energía eléctrica. En esta composición, el cielo se funde con la imponente estructura de la represa, mientras el follaje exuberante en su base completa el contexto natural. La escena nos invita a contemplar la armonía entre la ingeniería humana y el entorno natural, donde la fuerza del agua se transforma en una fuente limpia y renovable de energía",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00044.JPEG"
            },
            {
                "id": "00045",
                "title": "Valle del Lunarejo, Rivera",
                "description": "Recorrer el valle del Lunarejo en Rivera, Uruguay, es una experiencia que requiere varios días, pero cada momento vale la pena. Además de los paisajes cambiantes y diversos que ofrece este valle, lo que hace que la travesía sea aún más especial es la cálida hospitalidad de los propietarios de los campos. Su generosa bienvenida y disposición para compartir historias y conocimientos sobre la región enriquecen nuestra experiencia y nos hacen sentir como en casa en medio de la naturaleza. Este valle está salpicado de lugares de increíble belleza, donde el agua y las cascadas son los protagonistas indiscutibles. La melodía constante de los arroyos y el rugido ocasional de las cascadas nos envuelven en un ambiente de serenidad y asombro. Cada rincón revela una nueva maravilla natural: piscinas naturales de aguas cristalinas, paredes rocosas cubiertas de musgo y exuberante vegetación que se refleja en el espejo de agua. Es un verdadero paraíso para los amantes de la naturaleza y una invitación a desconectar del bullicio de la vida cotidiana y sumergirse en la tranquilidad y la belleza de nuestro entorno natural.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00045.jpeg"
            },
            {
                "id": "00046",
                "title": "Atardecer en las Sierras de Carapé",
                "description": "Atestiguar un momento de la tarde como este, donde las negras nubes se intercalan con los últimos rayos de sol, añadiendo un matiz especial al espectacular follaje que rodea el paisaje. Cada instante, cada respiración, es una oportunidad para detenerse y apreciar la belleza efímera de la naturaleza, recordándonos la importancia de vivir plenamente cada momento. Este paisaje se encuentra en las Sierras de Carapé, Maldonado, Uruguay, un lugar donde la majestuosidad de la naturaleza nos invita a reflexionar y a conectarnos con nuestro entorno.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00046.jpeg"
            },
            {
                "id": "00047",
                "title": "Las barrancas de San Pedro",
                "description": "Las costas de Colonia, Uruguay, ofrecen una diversidad sin igual. Mientras algunas playas se presentan vacías y solitarias, otras exhiben formaciones tan impresionantes como estas barrancas en la localidad de San Pedro. Compuestas por una mezcla de arena y arcilla, estas barrancas muestran fascinantes cuevas talladas por las aguas del Río de la Plata. Sus vibrantes colores añaden un toque espectacular a este destino, convirtiéndolo en un lugar imperdible para los amantes de la fotografía y la naturaleza.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00047.JPG"
            },
            {
                "id": "00048",
                "title": "Molinos y cerros",
                "description": "Este panorama captura la imponencia de las Sierras del Carape, en Maldonado, Uruguay. El horizonte está marcado por majestuosos cerros, adornados por una hilera de molinos de viento que destacan contra el cielo. En esta composición, jugué con el contraste entre el follaje exuberante, el cielo gris y los nubarrones oscuros, creando una atmósfera vibrante y cautivadora. Para lograr esta imagen, fusioné varias tomas verticales, dando como resultado una vista panorámica que resalta la belleza natural de este lugar.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00048.JPEG"
            },
            {
                "id": "00049",
                "title": "Colonia en blanco y negro",
                "description": "Me encanta explorar el poder de las imágenes en blanco y negro. Buscar el equilibrio perfecto de contrastes para resaltar un motivo destacado es todo un desafío creativo. Al convertir una escena a blanco y negro, se intensifican los ribetes y contrastes, creando una interacción única entre las luces y las sombras. Un cielo blanco contrastado con un sujeto más oscuro puede crear un efecto dramático, destacando la profundidad y la textura de la imagen. Esta técnica permite que el motivo se destaque de manera poderosa frente al color, capturando la atención del espectador y transmitiendo emociones de una manera impactante y evocadora. Foto tomada en Colonia del Sacramento, Uruguay.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00049.JPEG"
            },
            {
                "id": "00050",
                "title": "¡Bellos, ellos!",
                "description": "Este grupo de caballos se vio momentáneamente interrumpido en su tranquilidad por mi presencia, acompañada de mi cámara y todo lo que el humano representa para ellos. Sin embargo, en esa misma interacción surgió una oportunidad única para capturar imágenes excepcionales. El ambiente, el cielo y la majestuosidad del lugar se fusionaron para crear un escenario verdaderamente espectacular. Cada detalle contribuyó a enriquecer la composición, desde la serenidad de los caballos hasta la belleza natural que los rodeaba. Esta fotografía fue tomada en los alrededores de la laguna de Rocha, Uruguay, un lugar que rebosa de vida y encanto natural.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00050.JPEG"
            },
            {
                "id": "00051",
                "title": "Pastando en la serenidad",
                "description": "Esta fotografía fue tomada en los alrededores de la laguna de Rocha, Uruguay, un lugar espectacular que alberga una rica diversidad natural y rural. En esta zona, varios propietarios rurales crían ganado y caballos, convirtiendo el paisaje en un escenario vivo y dinámico. Mientras pasaba por el área, quedé cautivado por el interesante contraste entre los árboles, el cielo gris y el entorno circundante. Esta combinación de elementos creaba una atmósfera de majestuosidad y serenidad. Los caballos pastando añadían un toque de vida y movimiento a la escena, completando una imagen que captura la belleza y la armonía de la naturaleza en su estado más puro.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00051.JPEG"
            },
            {
                "id": "00052",
                "title": "La Laguna del Sauce",
                "description": "Este encantador paisaje se sitúa en las proximidades de la Laguna del Sauce, en Maldonado, muy cerca de Punta del Este. En este lugar, se encuentra una pequeña represa que marca el inicio de un serpenteante arroyo que eventualmente desemboca en la costa. La presencia de la represa agrega un elemento de interés y dinamismo al entorno natural, mientras que el arroyo serpenteante añade un toque de serenidad y fluidez al paisaje circundante. Durante mi visita a este hermoso paraje, pasé un día maravilloso explorando los diversos matices y rincones de la zona. En un momento dado, encontré este ángulo particular que ofrecía un encuadre fabuloso, capturando la belleza y la tranquilidad del entorno en una sola imagen.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00052.jpg"
            },
            {
                "id": "00053",
                "title": "Sobre el cerro Catedral, Uruguay",
                "description": "Esta vista panorámica se contempla desde lo alto del Cerro Catedral, en Maldonado, Uruguay, el punto más elevado del país. En este lugar, los caminos rurales serpentean a través del paisaje, brindándonos la oportunidad de explorar y descubrir estas espectaculares vistas. En esta composición, se entrelazan diversos elementos que contribuyen a su grandeza: los molinos de viento se alzan majestuosamente sobre el horizonte lejano, mientras que los caminos rurales se pierden en la distancia. El cielo se extiende vasto y abierto sobre nosotros, y el follaje verde de los árboles añade un toque de frescura y vitalidad al paisaje. Cada elemento se combina armoniosamente para crear una imagen que captura la belleza y la grandiosidad de este lugar único.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00053.jpeg"
            },
            {
                "id": "00054",
                "title": "La magia de Arazati ",
                "description": "Estas imágenes tienen un encanto especial, ¿verdad? Su simplicidad es su mayor fortaleza, ya que nos invitan a detenernos y apreciar el entorno que nos rodea. Son como pequeñas ventanas que se abren a paisajes cotidianos pero llenos de belleza y significado. Nos llevan a reflexionar sobre el lugar donde fueron capturadas, sobre qué hay más allá de la escena que vemos. ¿Qué historias se esconden en esos alrededores? ¿Qué secretos guarda ese lugar? Son preguntas que nos motivan a explorar, a descubrir más sobre el mundo que nos rodea. Esta fotografía en particular fue tomada en Arazatí, San José, Uruguay, un lugar que ofrece una riqueza de paisajes y experiencias por descubrir.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00054.JPEG"
            },
            {
                "id": "00055",
                "title": "Parque de Cufré",
                "description": "Esta fotografía captura la serenidad de un arroyo donde grandes rocas descansan sobre su lecho. Es un rincón mágico que nos invita a sumergirnos en la belleza natural de Uruguay y a apreciar la tranquilidad que nos ofrece. Cada detalle de esta escena transmite una sensación de calma y armonía con el entorno. Es un recordatorio de los tesoros que nos esperan en los límites entre los departamentos de San José y Colonia, donde la naturaleza nos regala paisajes excepcionales para explorar y disfrutar.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00055.JPG"
            },
            {
                "id": "00056",
                "title": "La Quinta",
                "description": "Esta encantadora instantánea fue capturada en Quinta Capurro, un lugar lleno de historia ubicado en el departamento de Canelones, Uruguay. Conocido por haber sido el destino de vacaciones de varios presidentes uruguayos a principios de siglo, este sitio respira historia y nostalgia. En un día soleado como el que tuve la suerte de experimentar, cada rincón de Quinta Capurro parecía invitar a la exploración, revelando sus secretos y bellezas a medida que uno se adentra en sus senderos.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00056.JPG"
            },
            {
                "id": "00057",
                "title": "Una sombra, en el firmamento",
                "description": "La imagen que ves aquí es el resultado de un efecto conocido como barrido, una técnica que implica el movimiento de la cámara mientras se toma la fotografía. Además, utilicé varias técnicas, como el focus stacking, para componer esta hermosa foto. Aunque se aleja un poco de la realidad, esta técnica permite potenciar el proceso fotográfico, creando imágenes dinámicas y visualmente impactantes. Para mí, fue todo un desafío lograr este efecto, pero también fue muy entretenido experimentar con diferentes técnicas y ver el resultado final.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00057.JPG"
            },
            {
                "id": "00058",
                "title": " Parajes de Rocha, Uruguay",
                "description": "Deambulando por Rocha, Uruguay, me encontré con este paisaje tan peculiar. Por un lado, la crudeza del invierno se refleja en la falta de vegetación del árbol, mientras que, por otro lado, una garita de guardavidas nos recuerda el contraste con la temporada de verano, cuando esta zona está llena de vida y actividad. La composición y los colores de la imagen tienen un aire vintage, como si hubiera sido tomada por una cámara antigua, lo que añade un encanto nostálgico a la escena.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00058.JPEG"
            },
            {
                "id": "00059",
                "title": "Un viejo habitante de Montevideo",
                "description": "Uno de los paisajes que no podemos dejar de apreciar cuando caminamos por la playa Ramírez, en Parque Rodó, Montevideo, es la imponente estructura del hoy llamado Edificio del Mercosur. Esta edificación, que originalmente fue uno de los hoteles de lujo más destacados de principios de siglo, conserva una majestuosidad que nos transporta a otra época. En sus inicios, este hotel hospedó a varios presidentes de Latinoamérica que visitaban la capital de Uruguay por negocios o placer. Hoy, el edificio se erige como un recordatorio de la grandeza arquitectónica de aquellos tiempos, contrastando notablemente con las construcciones más modernas que lo rodean. Su diseño detallado y su imponente presencia nos hacen reflexionar sobre la evolución urbana y la pérdida de la elegancia clásica en la arquitectura contemporánea.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00059.jpeg"
            },
            {
                "id": "00060",
                "title": "Esos rinconcitos",
                "description": "Una de mis pasiones es encontrar rincones que nos transmitan algo especial, esos lugares que cuentan historias y evocan emociones. En esta postal, una vieja barcaza de pesca contrasta con una casita de la zona de manera magistral. La barcaza, con su aspecto desgastado por el tiempo y las inclemencias, se enfrenta a la sencillez y el encanto rústico de la construcción detrás. Todo esto se combina de manera armoniosa con el agua que fluye suavemente debajo, creando una atmósfera de serenidad y nostalgia. Esta fotografía fue tomada en la desembocadura del arroyo Pando, en Canelones, Uruguay, un lugar donde el tiempo parece haberse detenido, invitándonos a detenernos y apreciar la quietud y la belleza del entorno.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00060.JPG"
            },
            {
                "id": "00061",
                "title": "Punta Carretas, Montevideo\n",
                "description": "La rambla de Punta Carretas, en Montevideo, Uruguay, ha sido en mi vida una cuna para mi pasión por la fotografía. He pasado largas horas recorriendo cada rincón de esta zona costera, buscando y encontrando innumerables enfoques maravillosos. Desde los atardeceres que tiñen el cielo de colores cálidos hasta los días nublados que dan un aire melancólico al paisaje, cada visita a la rambla me ofrece nuevas oportunidades para capturar la esencia del lugar. La combinación del mar, el cielo y la arquitectura de la ciudad crea un escenario perfecto para experimentar con diferentes composiciones y técnicas fotográficas. Sin dudas, volveré una y otra vez, maravillándome cada vez más con la belleza y la tranquilidad que este lugar ofrece, siempre con la esperanza de descubrir algo nuevo y emocionante a través del lente de mi cámara.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00061.JPG"
            },
            {
                "id": "00062",
                "title": "Piscinas naturales",
                "description": "Piscinas naturales, formadas entre las rocas de esta zona costera, ofrecen un espectáculo de belleza y serenidad que invita a la contemplación. Estas formaciones, llenas de agua cristalina, reflejan el cielo y el entorno de una manera mágica, creando un rincón de paz en medio del bullicio de la ciudad. Esta foto fue tomada en la rambla de Punta Carretas, Uruguay, junto al monumento al Holocausto del Pueblo Judío. Parte de la estructura del monumento se llega a ver en el fondo, añadiendo una dimensión histórica y emocional al paisaje.  El cielo, con sus nubes oscuras, crea un contraste magistral que resalta aún más la belleza de este lugar. La emoción de capturar un momento tan perfecto, donde la naturaleza y la historia se entrelazan, es indescriptible. Este lugar no solo ofrece una vista espectacular, sino también una conexión profunda con el pasado y un recordatorio de la resiliencia y la memoria. Cada visita a este rincón es una nueva oportunidad para descubrir y fotografiar la infinita belleza que ofrece la naturaleza en su forma más pura.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00062.JPG"
            },
            {
                "id": "00063",
                "title": "Testigo de mi ciudad",
                "description": "Esta panorámica, tomada desde el faro de Punta Carretas en Montevideo, Uruguay, captura la belleza de un lugar que nos invita a reflexionar y conectar con la naturaleza. La vista se extiende sobre la costa, mostrando el mar en su majestuosidad y las olas rompiendo suavemente contra las rocas, creando un ambiente sereno y evocador. En el momento preciso de la toma, un tero pasó volando justo en medio de mi encuadre, añadiendo un toque de vida y dinamismo a la imagen. El contraste del ave en pleno vuelo con el paisaje costero resalta la armonía entre la fauna y su entorno, y subraya la fragilidad y la belleza del instante capturado.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00063.jpeg"
            },
            {
                "id": "00064",
                "title": "Atardeceres mágicos",
                "description": "Un atardecer es otro motivo para encantarnos, un espectáculo natural que nos recuerda la belleza simple y poderosa del ciclo diario. Esta foto fue tomada desde la rambla del Parque Rodó, en Montevideo. La luz se reflejaba en el agua, creando destellos y sombras que danzan sobre la superficie. Las siluetas de las rocas se delineaban contra el cielo dorado, componiendo una escena de tranquila majestuosidad. Este atardecer quedará en mi memoria por la paz que me brindó al capturarlo. Cada fotografía es un testimonio de un instante irrepetible, una cápsula de tiempo que me transporta de nuevo a ese momento de conexión profunda con la naturaleza.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00064.JPEG"
            },
            {
                "id": "00065",
                "title": "Un trazado cautivante",
                "description": "El trazado del muelle desde la base de la imagen nos guía visualmente, facilitando la comprensión de la fotografía. Esta técnica, común en la fotografía, dirige la mirada del espectador hacia el punto focal y añade profundidad y perspectiva. La imagen también incorpora otros detalles técnicos, como el contraste de luces y sombras y los reflejos en el agua, que en conjunto crean una composición final exquisita.  Foto tomada en la rambla de Montevideo, Uruguay.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00065.JPG"
            },
            {
                "id": "00066",
                "title": "Una fuente con personalidad",
                "description": "Esta fuente está ubicada en el Parque Rodó de Montevideo, un lugar ideal para pasear y disfrutar del entorno natural. Este parque, con su exuberante vegetación y rincones encantadores, ofrece numerosas oportunidades para la fotografía. He pasado muchas veces por el lugar, explorando diferentes ángulos y perspectivas, siempre buscando capturar algo único. En esta ocasión, logré plasmar la esencia y belleza gracias a un colorido árbol que se encuentra en el fondo. Este árbol crea un impactante contraste con la fuente, destacando en un entorno natural que invita a la contemplación y el disfrute. La combinación de elementos, desde la estructura de la fuente hasta el follaje del árbol, crea una composición visualmente atractiva que captura la esencia de este hermoso lugar. Cada vez que visito el Parque Rodó, encuentro nuevos detalles para explorar y capturar con mi cámara. Es un espacio lleno de vida y belleza, donde la naturaleza se mezcla armoniosamente con la arquitectura y la creatividad humana. Sin duda, es uno de mis lugares favoritos para buscar inspiración y crear imágenes que transmitan la magia de este oasis urbano.",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00066.JPEG"
            },
            {
                "id": "00067",
                "title": "Cae la noche en la ciudad",
                "description": "Un sendero que invita a ser recorrido. Un atardecer que da paso a la noche, las luces de las farolas comienzan a encenderse. Son ingredientes esenciales de esta composición. Y por supuesto, la humedad del suelo que van creando unos bonitos reflejos. Foto tomada en la rambla de Montevideo, Uruguay. ",
                "imgPath": "photos-paisajes/",
                "imgName": "photo-00067.JPG"
            }
        ],
    },
    {
        id: 3,
        name: 'Digicam, Analógico: Retro Pero Actual',
        path: '/digicam',
        directory: 'photos-digicam',
        photos: [
            {
                "id": "00016",
                "title": "La Caseta",
                "description": "Ubicada en el puerto de Punta del Este, Maldonado, Uruguay, encontré esta garita, cerrada al público, lo que me brindó la oportunidad de tomar una fotografía con mi cámara analógica. Los colores provenientes del negativo acentúan su marcada tendencia retro, lo que la convierte en una imagen nostálgica, capaz de transportar a quien la observa a otro tiempo. Es increíble cómo en una escena cotidiana, como esa caseta cerrada, puede surgir tanta belleza, evocando sentimientos de nostalgia y encanto. Algo que deleita mis ojos y que demuestra que cada imagen que capturamos tiene el poder de convertir lo simple en algo único.\n- Camara: Zenit DF-300x, carrete: KODAK VISION3 250D",
                "imgPath": "photos-digicam/",
                "imgName": "photo-00016.jpg"
            },
            {
                "id": "00017",
                "title": "El Muelle con un destino",
                "description": "Un muelle o un pasillo por el cual caminar, un sendero marcado que invita a seguir avanzando, no hace más que deleitar mis ojos a la hora de buscar un enfoque. Como si, a lo lejos, se vislumbrara una meta, un destino, un lugar al cual llegar. Esta fotografía evoca una sensación de continuidad, un viaje que no solo se ve, sino que también se siente. Es maravilloso cómo logras capturar la esencia de un trayecto en una imagen, invitando a quien la observa a imaginar su propio camino, sus propias metas. \n- Camara: Zenit DF-300x / Carrete: KODAK VISION3 250D",
                "imgPath": "photos-digicam/",
                "imgName": "photo-00017.jpg"
            },
            {
                "id": "00018",
                "title": "El gato malhumorado",
                "description": "Allí estaba, disfrutando del sol, y yo, con mi cámara analógica, lo molestaba, buscando el mejor enfoque, algo que resaltara su paz, su penumbra, su sigilo. Las líneas del entorno, que nacen a los lados, dirigen la mirada al centro, donde él se encontraba. La composición me parece fantástica, a pesar de que era tan solo un simple gato disfrutando del sol. Las maravillas que captura la cámara solo podemos apreciarlas después, cuando las observamos con detenimiento. Es increíble cómo un momento tan sencillo puede convertirse en algo profundo cuando nos detenemos a ver más allá de lo evidente.\n- Camara: Zenit DF-300x / Carrete: KODAK VISION3 250D",
                "imgPath": "photos-digicam/",
                "imgName": "photo-00018.jpg"
            },
            {
                "id": "00019",
                "title": "Las guardianas",
                "description": "Un simple momento, una situación, una composición: son el marco perfecto para una escena deliciosa, donde la armonía entre la cámara analógica y su carrete logra capturar algo maravilloso. La tranquilidad de la escena despierta una sensación inquietante, como si en medio de esa calma se escondiera una historia no contada. Es fascinante cómo, con solo observar más de cerca, las imágenes capturadas pueden revelar emociones y pensamientos profundos. Lo que parece un instante estático se convierte en una ventana a nuevas percepciones, haciendo que cada toma sea única y significativa.\n- Camara: Zenit DF-300x / Carrete: KODAK VISION3 250D",
                "imgPath": "photos-digicam/",
                "imgName": "photo-00019.jpg"
            },
            {
                "id": "00020",
                "title": "Habitantes del muelle",
                "description": "Allí están, inmersos en su rutina diaria. Lo que para un turista es novedoso y fuera de lo común, para ellos es solo otro día de trabajo, otro bolsillo que llenar. La calidad de la imagen analógica evoca una nostalgia atípica, una que no proviene del pasado, sino de esa sensación de estar atrapado en un momento fugaz que, al ser capturado, adquiere un nuevo significado. Es curioso cómo la película analógica puede añadir una profundidad emocional, haciendo que lo cotidiano se vea como una escena digna de ser recordada, llena de matices que solo el tiempo puede revelar.\n- Camara: Zenit DF-300x, carrete: KODAK VISION3 250D",
                "imgPath": "photos-digicam/",
                "imgName": "photo-00020.jpg"
            },
            {
                "id": "00000",
                "title": "Castillo del Parque Rodó",
                "description": "El castillo del Parque Rodó, ubicado en la ciudad de Montevideo, o mejor dicho, la Biblioteca María Stagnero de Munar, es una imponente estructura que data de 1930. Unos años después, en las afueras del castillo, se inauguró el monumento a Guillermo Tell, personaje de la independencia de Suiza. Está hecho de bronce y granito por José Belloni, quien lo donó a la colectividad suiza por su centenario en Uruguay. Hoy en día lleva el nombre de María Stagnero de Munar, quien fue una destacada maestra uruguaya que fundó el Instituto Normal de Señoritas, donde se preparaban las maestras de enseñanza primaria.",
                "imgPath": "photos-digicam/",
                "imgName": "photo-00000.jpg"
            },
            {
                "id": "00001",
                "title": "Un día tormentoso",
                "description": "En un día nublado y tormentoso, ¡nada mejor que salir con la pequeña DIGICAM para capturar unas imágenes espectaculares! Es una oportunidad óptima para jugar con los contrastes entre la oscuridad de las nubes y la claridad de otros elementos. ¿Qué te parece explorar la belleza que se esconde incluso en los días más grises? ¡Seguro que lograremos capturar momentos sorprendentes y llenos de magia!",
                "imgPath": "photos-digicam/",
                "imgName": "photo-00001.jpg"
            },
            {
                "id": "00002",
                "title": "Faro de Punta Carretas",
                "description": "Capturar la esencia mágica del Faro de Punta Carreras con una cámara considerada vintage no hace más que avivar la llama de nuestra pasión por la fotografía. Cada vez que nos aventuramos con nuestra antiquísima cámara digital, sentimos cómo se despierta en nosotros una fascinación sin límites. Es increíble cómo esta herramienta, que algunos podrían considerar obsoleta, es capaz de capturar la belleza atemporal de lugares como este. Con cada clic, no solo estamos tomando una foto, sino que también estamos preservando un pedacito de historia y emoción.",
                "imgPath": "photos-digicam/",
                "imgName": "photo-00002.JPG"
            },
            {
                "id": "00003",
                "title": "En las inmediaciones",
                "description": "¡Qué bonita postal de las inmediaciones del Faro de Punta Carretas! Es un lugar congelado en el tiempo que invita a ser visitado. Esta foto fue capturada con una cámara del año 2012, una vieja pero confiable Canon Powershot A2500, que como se puede apreciar, sigue siendo capaz de lograr fotos espectaculares.",
                "imgPath": "photos-digicam/",
                "imgName": "photo-00003.JPEG"
            },
            {
                "id": "00004",
                "title": "El lago",
                "description": "Una postal del lago del Parque Rodó en Montevideo, Uruguay. Por más que pase el tiempo, este sitio permanece inmutable y espectacular. Es un lugar donde la serenidad y la belleza natural se fusionan en una armonía única que nunca deja de cautivar a quienes lo visitan. Esta foto, que captura la tranquila serenidad del lago, fue tomada con una cámara Canon Powershot A2500 del año 2012. A través de esta imagen, podemos apreciar la capacidad atemporal de este hermoso lugar para inspirar paz y contemplación en todos los que lo visitan.",
                "imgPath": "photos-digicam/",
                "imgName": "photo-00004.jpg"
            },
            {
                "id": "00005",
                "title": "Detrás del Castillo ",
                "description": "Esta instantánea la logré detrás del castillo del Parque Rodó, ubicado en la ciudad de Montevideo, o mejor dicho, la Biblioteca María Stagnero de Munar. Esta imponente estructura, que data de 1930, es un símbolo de la historia y la cultura de Uruguay. Su nombre rinde homenaje a una destacada maestra uruguaya que fundó el Instituto Normal de Señoritas, donde se preparaban las maestras de enseñanza primaria. La Biblioteca María Stagnero de Munar es mucho más que un edificio histórico; es un lugar de conocimiento y aprendizaje que ha sido testigo de innumerables historias a lo largo de los años. Esta foto, capturada con una cámara Canon Powershot A2500 del año 2012, es un tributo a la belleza y la importancia de este emblemático lugar en la ciudad de Montevideo.",
                "imgPath": "photos-digicam/",
                "imgName": "photo-00005.jpg"
            },
            {
                "id": "00006",
                "title": "El parque Rivera",
                "description": "El Parque Rivera, en la ciudad de Montevideo, es un lugar encantador para visitar con la familia. En esta instantánea en blanco y negro, intenté evocar la fotografía antigua o retro, capturando la esencia atemporal del parque. Utilicé una cámara Canon Powershot A2500 del año 2012 para lograr este efecto nostálgico. El resultado es una imagen que transporta al observador a otra época, resaltando la belleza clásica y la serenidad de este hermoso lugar.",
                "imgPath": "photos-digicam/",
                "imgName": "photo-00006.JPG"
            },
            {
                "id": "00007",
                "title": "Rambla Sur de Montevideo",
                "description": "La Rambla Sur de Montevideo es una obra emblemática que se inició a principios del siglo XX como parte de un ambicioso plan de modernización urbana. Su construcción oficial comenzó en 1923, bajo la dirección del ingeniero Eugenio Baroffio. La primera fase, completada en 1935, incluyó la creación de un muro de contención y un paseo peatonal que se extiende desde el Parque Rodó hasta la Plaza Virgilio. Esta rambla ha desempeñado un papel crucial en la protección de la ciudad contra inundaciones y ha proporcionado un valioso espacio recreativo a lo largo de la costa montevideana. Esta foto fue capturada con una cámara Canon Powershot A2500 del año 2012, destacando la belleza y la funcionalidad de esta histórica estructura.",
                "imgPath": "photos-digicam/",
                "imgName": "photo-00007.jpg"
            },
            {
                "id": "00008",
                "title": "Contemplando la ciudad",
                "description": "La Rambla Sur de Montevideo es una obra emblemática que se inició a Caminar por la Rambla Sur de Montevideo es una experiencia única que permite contemplar la ciudad desde una perspectiva privilegiada. A medida que avanzas por su extenso paseo peatonal, el sonido relajante de las olas y la brisa marina te acompañan, ofreciendo un respiro del bullicio urbano. La vista panorámica de los edificios históricos y modernos que se alzan frente al mar, junto con los parques y plazas que se encuentran a lo largo del recorrido, crea un escenario perfecto para disfrutar de la tranquilidad y la belleza de Montevideo. Esta caminata no solo invita a la reflexión y el disfrute del paisaje, sino que también permite conectar con la rica historia y cultura de la ciudad, haciendo de cada paso una inmersión en su encanto inigualable.  Cámara: Canon Powershot A2500 del año 2012. ",
                "imgPath": "photos-digicam/",
                "imgName": "photo-00008.jpg"
            },
            {
                "id": "00009",
                "title": "La pista de patín",
                "description": "Pasear por la Rambla Sur de Montevideo ofrece una oportunidad maravillosa para disfrutar de la ciudad y su entorno costero. A lo largo del paseo, uno puede observar la perfecta combinación entre la naturaleza y la arquitectura urbana, con vistas impresionantes del Río de la Plata y de la línea de edificios que delinean la costa. Los espacios verdes y las áreas recreativas invitan a hacer una pausa, respirar el aire fresco y admirar el horizonte. La rambla es también un punto de encuentro para los montevideanos, donde se puede ver a la gente caminando, corriendo, andando en bicicleta o simplemente descansando en los bancos. Es un recorrido que resalta la vitalidad y el carácter acogedor de Montevideo, proporcionando un espacio donde se puede apreciar la vida cotidiana de la ciudad en toda su autenticidad.  Camara: Canon Powershot A2500 del año 2012. ",
                "imgPath": "photos-digicam/",
                "imgName": "photo-00009.jpg"
            },
            {
                "id": "00010",
                "title": "De paseo",
                "description": "La Rambla Sur de Montevideo es un vibrante escenario de actividad y vida cotidiana. A cualquier hora del día, se puede ver a personas de todas las edades caminando con un ritmo tranquilo, disfrutando de la belleza del paisaje costero. Algunos aprovechan para hacer deporte, corriendo o andando en bicicleta, sintiendo la brisa marina en sus rostros. Otros se reúnen para charlar, sentados en los bancos o simplemente caminando lado a lado, compartiendo historias y risas. Este espacio se convierte en un punto de encuentro donde la comunidad se une, celebrando la simple alegría de estar al aire libre, en contacto con la naturaleza y con la ciudad que los rodea. La rambla es más que un paseo; es un reflejo del espíritu dinámico y amigable de Montevideo.",
                "imgPath": "photos-digicam/",
                "imgName": "photo-00010.JPG"
            },
            {
                "id": "00011",
                "title": "El ciclista",
                "description": "Entre los muchos transeúntes que disfrutan de la Rambla Sur de Montevideo, se puede ver a un joven pedaleando con entusiasmo en su bicicleta. Con el viento en el cabello, recorre el camino junto al mar, aprovechando cada momento de libertad y ejercicio al aire libre. A medida que avanza, esquiva suavemente a los caminantes y saluda a otros ciclistas con un gesto amistoso. La rambla se convierte en su pista personal, un lugar donde puede desconectar del mundo y sumergirse en la tranquilidad del entorno costero.   Cámara: Canon Powershot A2500 del año 2012. ",
                "imgPath": "photos-digicam/",
                "imgName": "photo-00011.JPG"
            },
            {
                "id": "00012",
                "title": "El muro",
                "description": "En este paisaje en blanco y negro se puede apreciar las zonas bajas de la rambla de Montevideo, donde el imponente muro desafía valientemente el paso del tiempo y las aguas del Río de la Plata. Esta estructura ha resistido las inclemencias del clima a lo largo de los años, protegiendo la ciudad de inundaciones y añadiendo un elemento de serenidad a la belleza escénica de la costa montevideana. La fotografía, con su composición espectacular y atmósfera evocadora, resalta los contrastes y texturas del paisaje, invitando a la contemplación de la fortaleza humana y la majestuosidad de la naturaleza en armonía. Cámara: Canon Powershot A2500 del año 2012. ",
                "imgPath": "photos-digicam/",
                "imgName": "photo-00012.jpg"
            },
            {
                "id": "00013",
                "title": "La roca",
                "description": "En esta postal en blanco y negro, los reflejos danzan sobre la superficie del agua, creando un juego de luces y sombras que cautiva la mirada del espectador. La imponente roca en la escena, de origen enigmático, se destaca con su presencia firme y desafiante, invitando a reflexionar sobre los misterios de la naturaleza y sus maravillas. Cámara: Canon Powershot A2500 del año 2012. ",
                "imgPath": "photos-digicam/",
                "imgName": "photo-00013.jpg"
            },
            {
                "id": "00014",
                "title": "Era su momento",
                "description": "Poder capturar ese momento tan especial y simple para ellos fue una verdadera gracia divina. Allí estaba yo, documentando su pasión, su momento de expansión, su conexión íntima con la naturaleza y el mundo que los rodeaba. Era un día muy frío, con un viento cortante y el cielo gris, pero eso no los detuvo.  Cámara: Canon Powershot A2500 del año 2012. ",
                "imgPath": "photos-digicam/",
                "imgName": "photo-00014.jpg"
            },
            {
                "id": "00015",
                "title": "La grandeza de nuestro entorno ",
                "description": "Nada me gusta más que poder transmitir la grandeza de nuestro entorno en una imagen. Capturar los ángulos que nos hacen ver la realidad desde otra perspectiva es una experiencia única. Cada foto es un documento de nuestra realidad, una verdad que a veces preferimos no ver, pero que está ahí, esperando ser descubierta por el buen observador. En cada instantánea, encuentro la oportunidad de revelar esos detalles ocultos, mostrando la belleza y complejidad del mundo que nos rodea.",
                "imgPath": "photos-digicam/",
                "imgName": "photo-00015.jpg"
            }
        ],
    },
    {
        id: 4,
        name: 'La Riviera',
        path: '/la-riviera',
        directory: 'photos-la-riviera',
        photos: [
            {
                "id": "00000",
                "title": "La Caseta del Guardavida",
                "description": "Durante los meses de invierno, cae en desuso. Todo lo útil que fue durante el breve verano contrasta con el abandono que ahora presenta. Fuerte y erguida, afronta las inclemencias del tiempo, esperando pacientemente la llegada de la próxima temporada veraniega. Mientras tanto, la calma, la brisa y la tranquilidad del lugar crean una cuna serena para sus días de quietud.\nFoto tomada en La Riviera, Rocha, Uruguay",
                "imgPath": "photos-la-riviera/",
                "imgName": "photo-00000.jpg"
            },
            {
                "id": "00001",
                "title": "La Larga Espera",
                "description": "Allí aguardan, a la espera de otra mañana, otra salida a pescar, otro día. Su propósito ha sido siempre ayudar al hombre a conquistar el agua, pero enfrentan largos días de quietud. Mientras tanto, un fotógrafo casual los inmortaliza, capturando ese instante de calma antes de que vuelvan a cumplir su misión.\nFoto tomada en La Riviera, Rocha, Uruguay",
                "imgPath": "photos-la-riviera/",
                "imgName": "photo-00001.jpg"
            },
            {
                "id": "00002",
                "title": "Atento a lo que suceda",
                "description": "Allí está, en el árbol más alto del lugar, con la mirada fija en todo lo que se mueva. Su actitud tenaz, como la de un guardián incansable, habla por sí misma. Habita en un entorno donde la actividad es escasa, sin duda el lugar perfecto para una vida tranquila y duradera.\nFoto tomada en La Riviera, Rocha, Uruguay",
                "imgPath": "photos-la-riviera/",
                "imgName": "photo-00002.jpg"
            },
            {
                "id": "00003",
                "title": "Protagonistas de un largo invierno",
                "description": "El frío, las lluvias y los días nublados forman el entorno que deben soportar durante varios meses del año. Sus largas ramas, desprovistas de follaje, no hacen más que mostrar las evidencias de esos duros días. Pero dentro de poco, el verano volverá a traer su luz y calor, devolviendo la vida a cada rincón, vistiendo de nuevo esas ramas con verdes hojas que danzan al compás de la brisa cálida.\nFoto tomada en La Riviera, Rocha, Uruguay",
                "imgPath": "photos-la-riviera/",
                "imgName": "photo-00003.jpg"
            },
            {
                "id": "00004",
                "title": "Reflejo en la Laguna",
                "description": "Sobre la superficie calma de la laguna, el árbol se duplica, como si la naturaleza creara su propio espejo. Cada rama y hoja parecen fundirse con el agua, dibujando un reflejo casi perfecto que solo se distorsiona con el leve movimiento de la brisa. El árbol, firme en la orilla, y su imagen invertida bajo el agua, parecen compartir un mismo suspiro, inmortalizando un instante en que lo real y lo imaginado se encuentran.\nFoto tomada en La Riviera, Rocha, Uruguay\n",
                "imgPath": "photos-la-riviera/",
                "imgName": "photo-00004.jpg"
            }
        ],
    },
];





export interface contentVideoType {
    url: string;
    title: string;
    thumbnail: string;
}
export const contentVideos: contentVideoType[] = [
    {
        "url": "https://youtu.be/R7VImMPyRfo?si=YywRUMIPQ_09rOYm",
        "title": "Por qué Salen Mal las Fotos 👀 ¿Qué es la Difracción?",
        "thumbnail": "https://i.ytimg.com/vi/R7VImMPyRfo/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/X-HwhcvUgkc?si=JdZdXtYD5Ugu8_sS",
        "title": "Persiguiendo el Sol 👉 Fotografía al Amanecer ♥️",
        "thumbnail": "https://i.ytimg.com/vi/X-HwhcvUgkc/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/IikHbvEuPDE?si=98efCKux2RNRoL20",
        "title": "Nikon 👉 Por qué me salió Caro Migrar a la Línea Z? 😱",
        "thumbnail": "https://i.ytimg.com/vi/IikHbvEuPDE/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/7CYgKsIdHK4?si=8RBNpJf_H76Yqg-4",
        "title": "Una Marca Fotográfica me Contacto 👉 Y pasó esto! 👀",
        "thumbnail": "https://i.ytimg.com/vi/7CYgKsIdHK4/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/Q6h5drGFeD4?si=cNcbJLfE--ZEqC63",
        "title": "La Riviera 👉 Un lugar de Ensueños Fotográficos! 😱",
        "thumbnail": "https://i.ytimg.com/vi/Q6h5drGFeD4/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/eENMLSZQTuI?si=gSZwZYSDdSl7DK9q",
        "title": "Cómo Vivir de la Fotografía y Ganar Dinero? 👀",
        "thumbnail": "https://i.ytimg.com/vi/eENMLSZQTuI/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/USUrtHWuEJ0?si=TUniHq800CsYJRRR",
        "title": "Descubro un Puente Escondido 👀 (Fotografía a Mano Alzada)",
        "thumbnail": "https://i.ytimg.com/vi/USUrtHWuEJ0/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/18UKksBPs-k?si=II8_HjTCVag4RTe-",
        "title": "Vale la pena Pagar una Suscripción a Adobe? 👀",
        "thumbnail": "https://i.ytimg.com/vi/18UKksBPs-k/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/x9vTyqyLCNE?si=mTR4oaFTD7Q1Vng_",
        "title": "Vale la pena la Fotografía Analógica? 👉 Probemos!",
        "thumbnail": "https://i.ytimg.com/vi/x9vTyqyLCNE/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/Jp6knOutdi0?si=-wHyqw5-o95HRelZ",
        "title": "Nikon D3200 👉 Ese Maldito modo Automático!",
        "thumbnail": "https://i.ytimg.com/vi/Jp6knOutdi0/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/wh4odNMHAbk?si=Ic1y1tEBL_PETVqV",
        "title": "Otro intento, Otro desafío 👉 Un Paisaje Contaminado! 👀",
        "thumbnail": "https://i.ytimg.com/vi/wh4odNMHAbk/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/RJcZCNcH8h8?si=hKph1RgDTvkW6TUu",
        "title": "Por qué la Nikon D6 vale Mil Dólares más cara que la Nikon Z9?",
        "thumbnail": "https://i.ytimg.com/vi/RJcZCNcH8h8/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/AhJHj4hVgQg?si=j3yb9JSPBOr2mPOO",
        "title": "Paisajes Desafiantes 👉 Consejos para Fotografiar Naturaleza Monótona 😱",
        "thumbnail": "https://i.ytimg.com/vi/AhJHj4hVgQg/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/1YZuzEdKdKU?si=askWCP2FbZJeONb5",
        "title": "Atentado contra Trump 👉 La impresionante foto de Evan Vucci",
        "thumbnail": "https://i.ytimg.com/vi/1YZuzEdKdKU/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/lXjz4ha8494?si=ER-tLG4PHgqzhbDB",
        "title": "DJI Osmo Pocket 3 👉 Servirá en Fotografía? 🫣",
        "thumbnail": "https://i.ytimg.com/vi/lXjz4ha8494/hqdefault.jpg"
    },

    {
        "url": "https://youtu.be/gbYCPnwtolI?si=mGhHq_URxpQ2tdB1",
        "title": "Youtube Vs Instagram 👉 Fortalezas y Debilidades 👀",
        "thumbnail": "https://i.ytimg.com/vi/gbYCPnwtolI/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/KeDZf6vMBVU?si=cLKBmYw3GrrQrf2N",
        "title": "¿Avergonzado por fotografiar en JPG? 👀 (RAW vs Jpeg)",
        "thumbnail": "https://i.ytimg.com/vi/KeDZf6vMBVU/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/hZlP508JnnI?si=9i456IRkr8f6DaVe",
        "title": "Luego de la Nikon Z6III 👉 Qué pasó con la Z7III ? 😐",
        "thumbnail": "https://i.ytimg.com/vi/hZlP508JnnI/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/o8pGJgHAjPM?si=p9JJarNOhtWxjGNO",
        "title": "Nikon Z6III 👉 El día después! 👀",
        "thumbnail": "https://i.ytimg.com/vi/o8pGJgHAjPM/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/TLM240ag7x8?si=CikRr2H_BDyyLDCI",
        "title": "La nueva Nikon Z6III 👉 Valdrá la Pena? 👀",
        "thumbnail": "https://i.ytimg.com/vi/TLM240ag7x8/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/Ci4dLKDo6qM?si=myAKkSO6hDTEe6xn",
        "title": "Fotografía Selfie 👉 Ese invento tan Macabro 👀",
        "thumbnail": "https://i.ytimg.com/vi/Ci4dLKDo6qM/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/ARqZ2CEm6qY?si=JSAJPgdJlxDgbuDA",
        "title": "Vivian Maier 👉 Fallecida fue Reconocida como La Niñera Fotógrafa",
        "thumbnail": "https://i.ytimg.com/vi/ARqZ2CEm6qY/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/IsRxgXFQgDY?si=CjESF5zPPGXZs6IM",
        "title": "Cámara de Fotos o Teléfono Celular 👉 Qué Elijo?",
        "thumbnail": "https://i.ytimg.com/vi/IsRxgXFQgDY/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/JkFRsHRI0CU?si=O7x5VAKZVqhM67Lh",
        "title": "DIGICAM 👉 No es la Cámara.. Eres Tú 👀",
        "thumbnail": "https://i.ytimg.com/vi/JkFRsHRI0CU/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/zT94ExXTkqg?si=-f42J-lCYHdl8Bll",
        "title": "137 Días con la DJI Osmo Pocket 3 👉 Qué tan buena es?",
        "thumbnail": "https://i.ytimg.com/vi/zT94ExXTkqg/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/BkGg6LAUGjI?si=q1EHiY86OleuCIak",
        "title": "DIGICAM 👉 Nikon Coolpix Vs Canon PowerShot 👀",
        "thumbnail": "https://i.ytimg.com/vi/BkGg6LAUGjI/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/9KzALcUK8kw?si=_ZUPnQV4ECgFxhMj",
        "title": "NIKON D3200 👀 Sirve para la fotografía de paisajes?",
        "thumbnail": "https://i.ytimg.com/vi/9KzALcUK8kw/hqdefault.jpg"
    },
    {
        "url": "https://youtu.be/Yc2kob072Qg",
        "title": "DIGICAM Para Fotografías en Blanco y Negro? 👀",
        "thumbnail": "https://i.ytimg.com/vi/Yc2kob072Qg/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/vD6FGrngoEk",
        "title": "La Hora Azul, Dorada y el Arco del Crepúsculo 😱 Fotografía",
        "thumbnail": "https://i.ytimg.com/vi/vD6FGrngoEk/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/ZEAs9OZBx-E",
        "title": "Para Qué Sirve el Histograma en Fotografía? 🌝",
        "thumbnail": "https://i.ytimg.com/vi/ZEAs9OZBx-E/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/X1tcJfpgy7g",
        "title": "Como Hice estas Fotos? 😱 Explorando",
        "thumbnail": "https://i.ytimg.com/vi/X1tcJfpgy7g/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/23Td0mr93nw",
        "title": "DJI Osmo Pocket 3 (En mi Opinión Personal.. 🤔)",
        "thumbnail": "https://i.ytimg.com/vi/23Td0mr93nw/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/2A8Jb9qGQ5E",
        "title": "Las DIGICAM - Tendencia en Fotos Retro!! 😱",
        "thumbnail": "https://i.ytimg.com/vi/2A8Jb9qGQ5E/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/GaO_NGr-cyw",
        "title": "Por Qué Usar ISO 50 en Fotografía? 🤔",
        "thumbnail": "https://i.ytimg.com/vi/GaO_NGr-cyw/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/nS3oqSm3_dE",
        "title": "Enormes Raíces en un Museo 😱 (Fotografía de Paisajes)",
        "thumbnail": "https://i.ytimg.com/vi/nS3oqSm3_dE/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/Tog0MCr8VeM",
        "title": "Mi Experiencia con Grabr.io - Compra de Cámara y Lentes 👀",
        "thumbnail": "https://i.ytimg.com/vi/Tog0MCr8VeM/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/_7Wu7sYfmsg",
        "title": "Puerto Ingles, Uruguay 🙌 Un Tesoro Oculto..",
        "thumbnail": "https://i.ytimg.com/vi/_7Wu7sYfmsg/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/s9eSe_b5Psw",
        "title": "Instagram - Cómo Publicar Mejores Fotos 🌝",
        "thumbnail": "https://i.ytimg.com/vi/s9eSe_b5Psw/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/uzUs7nqtAj4",
        "title": "Valiosas Lecciones y Desarrollo Personal (Fotografía)",
        "thumbnail": "https://i.ytimg.com/vi/uzUs7nqtAj4/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/c2iGXQG6L-I",
        "title": "Mejorar tus Fotos con Dodge and Burn (Photoshop)",
        "thumbnail": "https://i.ytimg.com/vi/c2iGXQG6L-I/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/hI0pSEvQJkE",
        "title": "Trascender luego de la Muerte? (Fotos que Perduraran)",
        "thumbnail": "https://i.ytimg.com/vi/hI0pSEvQJkE/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/Vy5Dlbrqpzw",
        "title": "La belleza natural de un Lugar Perdido (Fotografía de Paisajes)",
        "thumbnail": "https://i.ytimg.com/vi/Vy5Dlbrqpzw/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/889QtyED-QU",
        "title": "Así creo Videos (Youtube, Cámaras y Micrófonos)",
        "thumbnail": "https://i.ytimg.com/vi/889QtyED-QU/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/uRKyaWj0Kk0",
        "title": "Que tipo de Fotógrafos Queremos Ser? 😱",
        "thumbnail": "https://i.ytimg.com/vi/uRKyaWj0Kk0/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/f_cqfEu80K0",
        "title": "Luna Azul de Agosto 🌝 Como Fotografiarla?",
        "thumbnail": "https://i.ytimg.com/vi/f_cqfEu80K0/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/s9eSe_b5Psw",
        "title": "Instagram - Cómo Publicar Mejores Fotos 🌝",
        "thumbnail": "https://i.ytimg.com/vi/s9eSe_b5Psw/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/Tthuko8xAVU",
        "title": "Descubrí el Mejor Lugar pero pasó Algo 😮 (El Arte de Fotografiar)",
        "thumbnail": "https://i.ytimg.com/vi/Tthuko8xAVU/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/9FSOVEu6J6w",
        "title": "Me fui a hacer Fotos en una Playa 😱  Buscando Paisajes",
        "thumbnail": "https://i.ytimg.com/vi/9FSOVEu6J6w/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/s2XImdyzrJk",
        "title": "Lamentable - Un Monumento Histórico que NO se puede visitar!",
        "thumbnail": "https://i.ytimg.com/vi/s2XImdyzrJk/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/RdTIKERocuY",
        "title": "Explorando el Cerro más Alto 😮 Fotografía de Paisajes",
        "thumbnail": "https://i.ytimg.com/vi/RdTIKERocuY/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/KB_enEFfzWE",
        "title": "Un Mal día 😭  Para la Fotografía de Paisajes",
        "thumbnail": "https://i.ytimg.com/vi/KB_enEFfzWE/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/PLrUNPcPu8k",
        "title": "Horquillado o Bracketing: Así logro Mejores Fotos usando Lightroom y Photoshop",
        "thumbnail": "https://i.ytimg.com/vi/PLrUNPcPu8k/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/g0VwLvdf95A",
        "title": "El Aventurero - Canteras Abandonadas | Encontramos un Martin Pescador 😍",
        "thumbnail": "https://i.ytimg.com/vi/g0VwLvdf95A/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/_oXtgRMtKa8",
        "title": "Visitando el Valle de la Luna 👀  Fotografía de Paisajes",
        "thumbnail": "https://i.ytimg.com/vi/_oXtgRMtKa8/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/yUK97xdMtx0",
        "title": "Explorando la Naciente del Arroyo 👀  José Ignacio, Uruguay",
        "thumbnail": "https://i.ytimg.com/vi/yUK97xdMtx0/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/bL4VNeKNAik",
        "title": "Visitando la Isla del Mono 🙉  Fotografía de Paisajes",
        "thumbnail": "https://i.ytimg.com/vi/bL4VNeKNAik/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/xhE2H5hyqOw",
        "title": "Explorando Paisajes 🙌  En las Sierras de Uruguay",
        "thumbnail": "https://i.ytimg.com/vi/xhE2H5hyqOw/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/Hkk3Z-yPGCo",
        "title": "En el Jardín Botánico de Montevideo | Fotografía de Paisajes",
        "thumbnail": "https://i.ytimg.com/vi/Hkk3Z-yPGCo/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/YsuTB9l6IgM",
        "title": "Fotografías con Horquillado (o Bracketing) con tu iPhone 😱",
        "thumbnail": "https://i.ytimg.com/vi/YsuTB9l6IgM/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/uRKyaWj0Kk0",
        "title": "Que tipo de Fotógrafos Queremos Ser? 😱",
        "thumbnail": "https://i.ytimg.com/vi/uRKyaWj0Kk0/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/-IQSpF8awNM",
        "title": "Ashaninka - En la Selva Amazónica Brasileña",
        "thumbnail": "https://i.ytimg.com/vi/-IQSpF8awNM/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/0aLFc2MtZ6Q",
        "title": "Vida Salvaje 👀  En el Parque Lecocq, Uruguay",
        "thumbnail": "https://i.ytimg.com/vi/0aLFc2MtZ6Q/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/WtsWsT8wFXY",
        "title": "¡Felices fiestas y próspero 2023! - Aquí te cuento cómo surgió esto",
        "thumbnail": "https://i.ytimg.com/vi/WtsWsT8wFXY/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/3s5ldRFfLw4",
        "title": "Una Playa Perdida en Colonia, Uruguay (Explorando Paisajes)",
        "thumbnail": "https://i.ytimg.com/vi/3s5ldRFfLw4/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/AXPnetshEzw",
        "title": "Lugar Mágico: La Quinta Capurro, Uruguay | Fotografía de Paisajes",
        "thumbnail": "https://i.ytimg.com/vi/AXPnetshEzw/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/iqprrAZqZuw",
        "title": "Explorando la Cantera Abandonada 👀 😱",
        "thumbnail": "https://i.ytimg.com/vi/iqprrAZqZuw/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/xAjp9GM-B34",
        "title": "Explorando la Represa de Laguna del Sauce, Uruguay | Fotografía de Paisajes",
        "thumbnail": "https://i.ytimg.com/vi/xAjp9GM-B34/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/GAKSVRoNUpo",
        "title": "Fantásticas Dunas en la Laguna de Rocha, Uruguay | Fotografía de Paisajes",
        "thumbnail": "https://i.ytimg.com/vi/GAKSVRoNUpo/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/3z64B8ACGhI",
        "title": "Explorando Hermosos Paisajes de Arazatí, Uruguay 😍",
        "thumbnail": "https://i.ytimg.com/vi/3z64B8ACGhI/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/MWNrfyvpOzU",
        "title": "Explorando Las Sierras de Mahoma, Uruguay 😮 Fotografía de Paisajes",
        "thumbnail": "https://i.ytimg.com/vi/MWNrfyvpOzU/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/E1yi1Lagz3Q",
        "title": "Accidentado Día Fotográfico 😱 En las Grutas, Punta del Este",
        "thumbnail": "https://i.ytimg.com/vi/E1yi1Lagz3Q/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/mpHKEsjy1_g",
        "title": "Photography at Ushuaia 👉 The Start Of A Good Day - Part 2",
        "thumbnail": "https://i.ytimg.com/vi/mpHKEsjy1_g/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/N5eBUT-IAIQ",
        "title": "Efecto de Agua Sedosa en Fotografía 🌝",
        "thumbnail": "https://i.ytimg.com/vi/N5eBUT-IAIQ/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/0zLqMWzofdM",
        "title": "Ushuaia Argentina: End of the World Photoshoot Adventure | Part 1",
        "thumbnail": "https://i.ytimg.com/vi/0zLqMWzofdM/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/pJiLsAnf7cw",
        "title": "Descubro un Barco Abandonado, Uruguay | Fotografía de Paisajes",
        "thumbnail": "https://i.ytimg.com/vi/pJiLsAnf7cw/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/yUK97xdMtx0",
        "title": "Explorando la Naciente del Arroyo 👀 José Ignacio, Urugua",
        "thumbnail": "https://i.ytimg.com/vi/yUK97xdMtx0/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/-g8BMDN-JkM",
        "title": "Glacier Perito Moreno, Argentina 😱 Landscape photography",
        "thumbnail": "https://i.ytimg.com/vi/-g8BMDN-JkM/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/xUNU18yo1-Y",
        "title": "Exploración Fotográfica Usando ISO 50 o 100? 😮",
        "thumbnail": "https://i.ytimg.com/vi/xUNU18yo1-Y/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/9ArpmyecTxY",
        "title": "El Chaltén Argentina | Landscape Photography",
        "thumbnail": "https://i.ytimg.com/vi/9ArpmyecTxY/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/V8_-PfyQCn0",
        "title": "Bariloche, Argentina: A Journey Through Lens and Landscape 👀",
        "thumbnail": "https://i.ytimg.com/vi/V8_-PfyQCn0/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/nhdNyhOmjqE",
        "title": "Great Photos at Lacar Lake  (San Martín de los Andes, Argentina)",
        "thumbnail": "https://i.ytimg.com/vi/nhdNyhOmjqE/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/A0VAX0ozKQ8",
        "title": "Fotos en San Martin de los Andes - Fotografía de Paisaje",
        "thumbnail": "https://i.ytimg.com/vi/A0VAX0ozKQ8/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/l2pgMe71AdI",
        "title": "Laguna De Los Cuervos, Uruguay 👀 Landscape Photography",
        "thumbnail": "https://i.ytimg.com/vi/l2pgMe71AdI/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/Rgeq6uGIiAM",
        "title": "Photography at Grutas de Salamanca, Uruguay",
        "thumbnail": "https://i.ytimg.com/vi/Rgeq6uGIiAM/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/rNL3AiE-f8A",
        "title": "How to use ND FILTER? | Uruguay LANDSCAPE PHOTOGRAPHY",
        "thumbnail": "https://i.ytimg.com/vi/rNL3AiE-f8A/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/_-FXGe8OaKI",
        "title": "How to use a CPL FILTER | Uruguay LANDSCAPE PHOTOGRAPHY",
        "thumbnail": "https://i.ytimg.com/vi/_-FXGe8OaKI/hqdefault.jpg"
    }, {
        "url": "https://youtu.be/HEOQ3XZEUfQ",
        "title": "LANDSCAPE PHOTOGRAPHY in MONTEVIDEO",
        "thumbnail": "https://i.ytimg.com/vi/HEOQ3XZEUfQ/hqdefault.jpg"
    }
];





